import React from 'react';

const HeroImage = () => (
  <svg
    data-name="Layer 1"
    viewBox="0 0 868 731"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="b" x1="731.5" x2="731.5" y1="630" y2="105" gradientUnits="userSpaceOnUse">
        <stop stop-color="gray" stop-opacity=".25" offset="0" />
        <stop stop-color="gray" stop-opacity=".12" offset=".54" />
        <stop stop-color="gray" stop-opacity=".1" offset="1" />
      </linearGradient>
      <linearGradient id="j" x1="136.5" x2="136.5" y1="632" y2="107"  />
      <linearGradient id="a" x1="136.5" x2="136.5" y1="190" y2="159" gradientUnits="userSpaceOnUse">
        <stop stop-opacity=".12" offset="0" />
        <stop stop-opacity=".09" offset=".55" />
        <stop stop-opacity=".02" offset="1" />
      </linearGradient>
      <linearGradient id="i" x1="732.5" x2="732.5" y1="237" y2="206"  />
      <linearGradient id="h" x1="731.5" x2="731.5" y1="285" y2="254"  />
      <linearGradient id="g" x1="731.5" x2="731.5" y1="333" y2="302"  />
      <linearGradient id="k" x1="731.5" x2="731.5" y1="381" y2="350"  />
      <linearGradient id="c" x1="731.5" x2="731.5" y1="429" y2="398"  />
      <linearGradient id="e" x1="731.5" x2="731.5" y1="477" y2="446"  />
      <linearGradient id="u" x1="731.5" x2="731.5" y1="525" y2="494"  />
      <linearGradient id="q" x1="731.5" x2="731.5" y1="573" y2="542"  />
      <linearGradient id="n" x1="731.5" x2="731.5"  />
      <linearGradient id="p" x1="51" x2="222" y1="223.5" y2="223.5"  />
      <linearGradient id="f" x1="51" x2="222" y1="270.5" y2="270.5"  />
      <linearGradient id="m" x1="51" x2="222" y1="317.5" y2="317.5"  />
      <linearGradient id="t" x1="51" x2="222" y1="365.5" y2="365.5"  />
      <linearGradient id="l" x1="51" x2="222" y1="413.5" y2="413.5"  />
      <linearGradient id="d" x1="51" x2="222" y1="461.5" y2="461.5"  />
      <linearGradient id="r" x1="132" x2="735" y1="664" y2="664"  />
      <linearGradient id="o" x1="435" x2="435" y1="89" y2="50"  />
      <linearGradient id="s" x1="601" x2="601" y1="704.8" y2="406"  />
    </defs>
    <title>welcome</title>
    <rect x="69" width="731" height="731" fill="#8c52ff" opacity=".2" />
    <rect x="179" y="68.25" width="510" height="595.5" fill="#8c52ff" opacity=".5" />
    <rect x="595" y="105" width="273" height="525" fill="url(#b)" />
    <rect y="107" width="273" height="525" fill="url(#j)" />
    <rect x="604" y="113" width="255" height="506" fill="#8c52ff" />
    <rect
      transform="translate(439 816.5) rotate(180)"
      x="175"
      y="197.5"
      width="255"
      height="506"
      fill="#8c52ff"
    />
    <rect x="51" y="159" width="171" height="31" fill="url(#a)" />
    <rect x="647" y="206" width="171" height="31" fill="url(#i)" />
    <rect x="646" y="254" width="171" height="31" fill="url(#h)" />
    <rect x="646" y="302" width="171" height="31" fill="url(#g)" />
    <rect x="646" y="350" width="171" height="31" fill="url(#k)" />
    <rect x="646" y="398" width="171" height="31" fill="url(#c)" />
    <rect x="646" y="446" width="171" height="31" fill="url(#e)" />
    <rect x="646" y="494" width="171" height="31" fill="url(#u)" />
    <rect x="646" y="542" width="171" height="31" fill="url(#q)" />
    <rect x="646" y="159" width="171" height="31" fill="url(#n)" />
    <rect x="51" y="208" width="171" height="31" fill="url(#p)" />
    <rect x="51" y="255" width="171" height="31" fill="url(#f)" />
    <rect x="51" y="302" width="171" height="31" fill="url(#m)" />
    <rect x="51" y="350" width="171" height="31" fill="url(#t)" />
    <rect x="51" y="398" width="171" height="31" fill="url(#l)" />
    <rect x="55" y="163" width="164" height="24" fill="#fff" />
    <rect x="54.5" y="210" width="164" height="24" fill="#fff" />
    <rect x="54.5" y="258" width="164" height="24" fill="#fff" />
    <rect x="54.5" y="306" width="164" height="24" fill="#fff" />
    <rect x="54.5" y="354" width="164" height="24" fill="#fff" />
    <rect x="54.5" y="402" width="164" height="24" fill="#fff" />
    <rect x="51" y="446" width="171" height="31" fill="url(#d)" />
    <rect x="54.5" y="450" width="164" height="24" fill="#fff" />
    <rect x="54.5" y="450" width="164" height="24" fill="#fff" />
    <rect x="54.5" y="498" width="164" height="24" fill="#fff" />
    <rect x="54.5" y="546" width="164" height="24" fill="#fff" />
    <rect x="650" y="162" width="164" height="24" fill="#fff" />
    <rect x="650" y="210" width="164" height="24" fill="#fff" />
    <rect x="650" y="258" width="164" height="24" fill="#fff" />
    <rect x="650" y="306" width="164" height="24" fill="#fff" />
    <rect x="650" y="354" width="164" height="24" fill="#fff" />
    <rect x="650" y="402" width="164" height="24" fill="#fff" />
    <rect x="650" y="450" width="164" height="24" fill="#fff" />
    <rect x="650" y="498" width="164" height="24" fill="#fff" />
    <rect x="650" y="546" width="164" height="24" fill="#fff" />
    <rect x="132" y="645" width="603" height="38" fill="url(#r)" />
    <rect x="137" y="649" width="594" height="30" fill="#8c52ff" />
    <rect x="134" y="50" width="602" height="39" fill="url(#o)" />
    <rect x="137" y="53" width="594" height="30" fill="#8c52ff" />
    <rect x="289" y="113" width="292" height="506" fill="#263238" opacity=".5" />
    <path
      transform="translate(-166 -84.5)"
      d="m825.66 417.55c7 9.38-3.48 20.46-3.48 20.46l1.76 0.61c-2.59 2.87-12.82 15.82-52.26 74.4-50.5 75-118.55 93.71-118.55 93.71a84 84 0 0 0-27.73-14.31v-7.08a83 83 0 0 0 55.3-53.48 85.08 85.08 0 0 0 9.14-38.44c0-48-39.77-86.94-88.82-86.94s-88.82 38.93-88.82 86.94a85.08 85.08 0 0 0 9.14 38.44 83 83 0 0 0 55.3 53.48v7.08a84 84 0 0 0-27.73 14.31s-68.08-18.73-118.58-93.73c-39.44-58.58-49.67-71.54-52.26-74.4l1.76-0.61s-10.45-11.08-3.48-20.46-46.15-20.46-40.06 0 13.93 30.69 13.93 30.69 111.2 175.38 169.25 204.36a80.43 80.43 0 0 0-2.06 18.12v34.1h167.18v-34.1a80.43 80.43 0 0 0-2.06-18.12c58-29 169.25-204.35 169.25-204.35s7.84-10.23 13.93-30.69-47.02-9.37-40.05 0.01z"
      fill="url(#s)"
    />
    <circle cx="435" cy="413.47" r="85.06" fill="#263238" />
    <circle cx="435" cy="426.82" r="80.06" fill="#f8c198" />
    <circle cx="403.31" cy="409.3" r="10.01" fill="#263238" />
    <circle cx="468.36" cy="409.3" r="10.01" fill="#263238" />
    <path
      transform="translate(-166 -84.5)"
      d="M624.35,548c0,6-10.45,15.85-23.35,15.85S577.65,554,577.65,548s10.45-5.84,23.35-5.84S624.35,542,624.35,548Z"
      fill="#fff"
    />
    <circle cx="405.81" cy="409.3" r="3.34" fill="#fff" />
    <circle cx="471.69" cy="409.3" r="3.34" fill="#fff" />
    <path
      transform="translate(-166 -84.5)"
      d="m609 466.88c-4.88 0-9.26-4.24-9.78-9.09a12 12 0 0 1 7-11.75 3.42 3.42 0 0 1 1.47-0.38c1.68 0 2.51 2 3.05 3.59 1.68 4.92 4.66 9.72 9.34 12s11.22 1.11 13.69-3.47c-6.62-2.95-9.45-12.21-5.6-18.35 1.49-2.37 3.81-4.38 4.28-7.14 0.86-5-4.62-8.64-9.47-10.14-14-4.33-62.8-4.81-65.49 16.08-2.82 22.04 36.14 31.57 51.51 28.65z"
      fill="#263238"
    />
    <ellipse
      transform="translate(-317.38 143.66) rotate(-21.53)"
      cx="524.27"
      cy="512.15"
      rx="5"
      ry="14.18"
      fill="#f8c198"
    />
    <ellipse
      transform="translate(-213.42 870.11) rotate(-68.47)"
      cx="677.73"
      cy="512.15"
      rx="14.18"
      ry="5"
      fill="#f8c198"
    />
    <path
      transform="translate(-166 -84.5)"
      d="m650.91 608.84s65.17-18.3 113.54-91.69 50.87-73.59 50.87-73.59l25.85 10.21s-120.08 193.48-171.78 203.49-18.48-48.42-18.48-48.42z"
      fill="#ff9800"
    />
    <path
      transform="translate(-166 -84.5)"
      d="m812.83 443.77s10-10.84 3.34-20 44.2-20 38.36 0-13.34 30-13.34 30z"
      fill="#f8c198"
    />
    <path
      transform="translate(-166 -84.5)"
      d="m551.09 608.84s-65.17-18.3-113.54-91.69-50.87-73.59-50.87-73.59l-25.85 10.21s120.09 193.48 171.8 203.49 18.46-48.42 18.46-48.42z"
      fill="#ff9800"
    />
    <path
      transform="translate(-166 -84.5)"
      d="m389.17 443.77s-10-10.84-3.34-20-44.2-20-38.36 0 13.34 30 13.34 30z"
      fill="#f8c198"
    />
    <g fill="#fff" opacity=".2">
      <path
        transform="translate(-166 -84.5)"
        d="m628.29 427.27c-0.48 2.76-2.8 4.76-4.28 7.14-3.37 5.38-1.62 13.16 3.32 17a12.79 12.79 0 0 1 0.85-11.14c1.49-2.37 3.81-4.38 4.28-7.14 0.54-3.13-1.41-5.73-4.13-7.6a6 6 0 0 1-0.04 1.74z"
      />
      <path
        transform="translate(-166 -84.5)"
        d="M607.46,446.51c-.32-.75-.62-1.51-.88-2.27-.54-1.59-1.37-3.54-3.05-3.59a3.42,3.42,0,0,0-1.47.38,12,12,0,0,0-7,11.75,10.53,10.53,0,0,0,4.47,7.35,9.4,9.4,0,0,1-.3-1.51,12,12,0,0,1,7-11.75A3.87,3.87,0,0,1,607.46,446.51Z"
      />
      <path
        transform="translate(-166 -84.5)"
        d="m600.41 462.35c-12.16 0.51-31.78-4.13-41.45-14 7.22 15.36 37.05 21.79 50 19.32a10.08 10.08 0 0 1-8.55-5.32z"
      />
      <path
        transform="translate(-166 -84.5)"
        d="m628.68 454.11c-2.92 3.43-8.58 4.15-12.75 2.12a14.54 14.54 0 0 1-3.7-2.61 17.84 17.84 0 0 0 7.87 8.44c4.68 2.27 11.22 1.11 13.69-3.47a11.88 11.88 0 0 1-5.11-4.48z"
      />
    </g>
    <path
      transform="translate(-166 -84.5)"
      d="m837.85 453.77s-120.09 193.48-171.85 203.49a60.33 60.33 0 0 1-9.07 1.11 53.05 53.05 0 0 0 12.41-1.11c51.71-10 171.8-203.49 171.8-203.49l-2.68-0.95c-0.37 0.63-0.61 0.95-0.61 0.95z"
      fill="#fff"
      opacity=".2"
    />
    <path
      transform="translate(-166 -84.5)"
      d="m364.15 453.77s120.09 193.48 171.85 203.49a60.33 60.33 0 0 0 9.07 1.11 53.05 53.05 0 0 1-12.41-1.11c-51.71-10-171.8-203.49-171.8-203.49l2.68-0.95c0.37 0.63 0.61 0.95 0.61 0.95z"
      fill="#fff"
      opacity=".2"
    />
    <path
      d="m435 506.88a80.06 80.06 0 0 1 80.06 80.06v33.36h-160.12v-33.36a80.06 80.06 0 0 1 80.06-80.06z"
      fill="#ff9800"
    />
    <path
      transform="translate(-166 -84.5)"
      d="m601.83 591.8a80.93 80.93 0 0 0-8.72 0.47c1 0 1.91-0.06 2.88-0.06a80.06 80.06 0 0 1 80.06 80.06v32.94h5.84v-33.35a80.06 80.06 0 0 0-80.06-80.06z"
      fill="#fff"
      opacity=".2"
    />
    <path
      transform="translate(-166 -84.5)"
      d="m600.17 591.8a80.93 80.93 0 0 1 8.72 0.47c-1 0-1.91-0.06-2.88-0.06a80.06 80.06 0 0 0-80.06 80.06v32.94h-5.84v-33.35a80.06 80.06 0 0 1 80.06-80.06z"
      fill="#fff"
      opacity=".2"
    />
    <rect x="411.65" y="484.78" width="46.7" height="56.29" rx="23.35" ry="23.35" fill="#f8c198" />
  </svg>
);

export default HeroImage;
