import React from 'react';
import AboutUsImage from '../../svg/AboutUsImage';

export default function AboutUs() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              About Us
            </h2>
            <p className="text-xl text-gray-700 md:text-lg">
              Sigar Indonesia (PT Sigar IT Cloud Services) started it's journey in Feb 2020 focusing on building stable and scalable web
              applications such as Sigar HRMS and Sigar POS solution. We aim to provide best in
              class services to our customers with next gen technologies thorugh innovative and creative
              workforce.
            </p>
          </div>
          <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
            <div className="bg-white border-l-4 shadow-sm border-primary-lighter">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 text-xl font-semibold leading-5">Vision and Mission</h6>
                <p className="text-md text-gray-900">
                  To be the first choice for our customers for any technology and business
                  transformation strategy &amp; solutions.
                </p>
              </div>
            </div>
            <div className="bg-white border-l-4 shadow-sm border-primary-lighter">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 text-xl font-semibold leading-5">Values</h6>
                <div className="px-5 text-md text-gray-900">
                  <ul class="list-disc">
                    <li>Customer First</li>
                    <li>Integrity</li>
                    <li>People</li>
                    <li>Innovation</li>
                    <li>Making a difference</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <AboutUsImage></AboutUsImage>
        </div>
      </div>
    </div>
  );
}
