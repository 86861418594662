import React from 'react';
/* This example requires Tailwind CSS v2.0+ */
import {
  UserGroupIcon,
  CashIcon,
  CubeTransparentIcon,
  GlobeAltIcon,
  DesktopComputerIcon,
  BriefcaseIcon,
  PresentationChartLineIcon,
  DatabaseIcon,
  ChipIcon,
} from '@heroicons/react/outline';

const products = [
  {
    name: 'Sigar HRMS',
    description: ['Employee Information', 'Payroll', 'Attendance', 'Performance Management', 'Taxation & BPJS', 'Flexi benefits' , 'Administration'],
    icon: DesktopComputerIcon,
  },
  {
    name: 'Sigar POS',
    description:
      ['Products', 'Inventory', 'Customers', 'Marketing', 'Staff', 'Billing & Payments', 'Dashboard', 'Reports & Analytics'],
    icon: CashIcon,
  },
  {
    name: 'AI Enabled Virtual Assistant',
    description:
      ['Conversational Self Service', 'Agent Authentication', 'Agent Assistant', 'Interaction Analytics', 'Cloud or On Premise Implementation'],
    icon: CubeTransparentIcon,
  },
  {
    name: 'IOT Products',
    description:
      ['Smart Parking', 'Smart Street Light', 'Fleet Management'],
    icon: ChipIcon,
  },
];

const services = [
  {
    name: 'Staff Augmentation',
    description:
      ['Managed Services (HR & IT)', 'Skill set & Capabilities', 'Cost effectiveness', 'Training & Development'],
    icon: UserGroupIcon,
  },
  {
    name: 'Project Management',
    description:
      ['PMO', 'Business Requirement & Functional Specifications', 'Development', 'Project Implementation', 'Testing', 'Deployment'],
    icon: BriefcaseIcon,
  },
  {
    name: 'Data Analytics & Intelligence',
    description:
      ['Data Ingestion & cleaning', 'Data Science', 'Data Engineering', 'AI & Machine Learning', 'Dashboards & Reporting'],
    icon: PresentationChartLineIcon,
  },
  {
    name: 'Data Center Services',
    description:
      ['Data Center Implementation', 'Data Center Management', 'Server & Storage', 'Backup & Security Deployment'],
    icon: DatabaseIcon,
  },
  {
    name: 'Business & Technology Consulting',
    description:
      ['Strategic Consulting', 'Market Research', 'Cost vs Benefit Analysis', 'Business Assurance', 'Technology Advisory'],
    icon: GlobeAltIcon,
  },
];

export default function Offerings() {
  return (
    <div>
      <h1 className="my-6 font-sans text-3xl font-bold text-center sm:text-4xl sm:leading-none">
        Our Offerings
      </h1>

      <h2 className="my-6 text-2xl text-center sm:text-3xl sm:leading-none text-primary-lighter">
        Products
      </h2>
      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0">
          <svg viewBox="0 0 88 88" className="w-full max-w-screen-xl text-primary">
            <circle fill="currentColor" cx="44" cy="44" r="15.5" />
            <circle fillOpacity="0.2" fill="currentColor" cx="44" cy="44" r="44" />
            <circle fillOpacity="0.2" fill="currentColor" cx="44" cy="44" r="37.5" />
            <circle fillOpacity="0.3" fill="currentColor" cx="44" cy="44" r="29.5" />
            <circle fillOpacity="0.3" fill="currentColor" cx="44" cy="44" r="22.5" />
          </svg>
        </div>

        <div className="relative grid gap-5 sm:grid-cols-1 lg:grid-cols-2">
          {products.map((product) => (
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div class="inline-flex space-x-4 mb-4">
                  <div className="flex items-center justify-center w-8 h-8 rounded-full text-white bg-primary-lighter">
                    <product.icon></product.icon>
                  </div>
                  <p className="text-lg font-bold">{product.name}</p>
                </div>
                <div className="text-md grid ml-3 lg:grid-cols-2 sm:grid-cols-1">
                  {product.description.map((line) => (
                    <li>{line}</li>
                  ))}
                </div>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
          ))}
        </div>
      </div>

      <h2 className="my-6 text-3xl text-center sm:text-4xl sm:leading-none text-primary-lighter">
        Services
      </h2>
      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0">
          <svg viewBox="0 0 88 88" className="w-full max-w-screen-xl text-primary">
            <circle fill="currentColor" cx="44" cy="44" r="15.5" />
            <circle fillOpacity="0.2" fill="currentColor" cx="44" cy="44" r="44" />
            <circle fillOpacity="0.2" fill="currentColor" cx="44" cy="44" r="37.5" />
            <circle fillOpacity="0.3" fill="currentColor" cx="44" cy="44" r="29.5" />
            <circle fillOpacity="0.3" fill="currentColor" cx="44" cy="44" r="22.5" />
          </svg>
        </div>

        <div className="relative grid gap-5 sm:grid-cols-2 lg:grid-cols-2">
          {services.map((service) => (
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
              <div class="inline-flex space-x-4 mb-4">
                  <div className="flex items-center justify-center w-8 h-8 rounded-full text-white bg-primary-lighter">
                    <service.icon></service.icon>
                  </div>
                  <p className="text-lg font-bold">{service.name}</p>
                </div>
                <div className="text-md grid ml-3 lg:grid-cols-2 sm:grid-cols-1">
                  {service.description.map((line) => (
                    <li>{line}</li>
                  ))}
                </div>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
