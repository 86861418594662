import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default () => (
  <div className="flex flex-col mx-10">
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Contact Form</h2>
    <p className="leading-relaxed mb-5 text-gray-600">
      Kindly key in your details below so we can contact you.
    </p>
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
      }}
      onSubmit={(values, actions) => {
        fetch(process.env.GATSBY_EMAIL_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.GATSBY_EMAIL_API_KEY,
          },
          body: JSON.stringify(encodeValues(values)),
        })
          .then(() => {
            toast.success('🚀 Your message has been delivered', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            actions.resetForm();
          })
          .catch(() => {
            toast.error('🛑 Your message was not delivered, try again or send us an email', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .finally(() => actions.setSubmitting(false));
      }}
      validate={(values) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const errors = {};
        if (!values.name) {
          errors.name = 'Kindly input a valid name';
        }
        if (!values.email || !emailRegex.test(values.email)) {
          errors.email = 'Kindly input a valid email';
        }
        if (!values.message) {
          errors.message = 'Kindly input a valid message';
        }
        return errors;
      }}
    >
      {() => (
        <Form>
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-600">
              Name^
            </label>
            <Field
              type="text"
              id="name"
              name="name"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
            <ErrorMessage component="div" className="text-red-600" name="name" />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Email^
            </label>
            <Field
              type="email"
              id="email"
              name="email"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
            <ErrorMessage component="div" className="text-red-600" name="email" />
          </div>
          <div className="relative mb-4">
            <label htmlFor="message" className="leading-7 text-sm text-gray-600">
              Message^
            </label>
            <Field
              id="message"
              name="message"
              component="textarea"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            />
            <ErrorMessage component="div" className="text-red-600" name="message" />
          </div>
          <button
            type="submit"
            className="text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            Send Message
          </button>
        </Form>
      )}
    </Formik>
  </div>
);

function encodeValues(formValues) {
  const objToReturn = {
    sender: {
      name: 'Sigar Indonesia Email Agent',
      email: 'no-reply@sigarindonesia.com',
    },
    to: [
      {
        email: 'sales@sigarindonesia.com',
        name: 'Sales - Sigar Indonesia',
      },
    ],
    subject: 'Received client message on website',
    htmlContent: '<html><head></head><body><p>' + JSON.stringify(formValues) + '</p></body></html>',
  };

  return objToReturn;
}
