import React from 'react';

import SriMuruganLogo from '../../images/sri-murugan-logo.jpg';
import PTRaddIndoWorldLogo from '../../images/pt-radd-indo-world.png';
import UniphoreLogo from '../../images/uniphore-logo.png';
import SubexLogo from '../../images/subex-logo.png';
import IndosatLogo from '../../images/indosat-logo.png'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import CardTemplate from '../sections/CardTemplate';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const customers = [
  {
    name: 'Indosat',
    description:
      '',
    image: IndosatLogo,
  },
  {
    name: 'Sri Murugan',
    description:
      '',
    image: SriMuruganLogo,
  },
  {
    name: 'Subex',
    description:
      '',
    image: SubexLogo,
  },
  {
    name: 'Uniphore',
    description:
      '',
    image: UniphoreLogo,
  },
  {
    name: 'PT Radd Indo World',
    description:
      '',
    image: PTRaddIndoWorldLogo,
  },
];

export default function Customers() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Our Customers</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
        </h2>
        <p className="text-gray-700 lg:text-md lg:max-w-md">
          Our team has had the pleasure to work with an impressive portfolio of clients who have shown their trust in Sigar Indonesia
        </p>
      </div>

      <div class="containerx">
        {/* <div class="flex flex-wrap -m-4"> */}
        <Carousel
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          transitionDuration={500}
          className="z-10"
        >
          {customers.map((customer) => (
            <CardTemplate item={customer}></CardTemplate>
          ))}
        </Carousel>
        {/* </div> */}
      </div>
    </div>
  );
}
