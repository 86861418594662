import React from 'react';
import ContactForm from './ContactForm';

export default function ContactUs() {
  return (
    <div className="container mx-auto p-5 bg-primary-lighter rounded-lg">
      <h3 className="text-5xl font-semibold text-white text-center">
        Ready to grow your business?
      </h3>
      <p className="mt-8 text-xl font-light text-white text-center">
        Feel free to drop us a message below and we will get in touch with you soon.
      </p>
      <div className="container flex flex-wrap my-5 mx-auto p-5 bg-white rounded-lg">
        <iframe
          className="flex-1"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.7289245294406!2d106.8570907292065!3d-6.142025866315456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f55894debcbd%3A0xda11d60d41d2e22!2sPT%20Sigar%20IT%20Cloud%20Services!5e0!3m2!1sen!2sid!4v1621076369676!5m2!1sen!2sid"
          width="700"
          height="500"
          allowfullscreen=""
          loading="lazy"
        ></iframe>

        <div className="flex-1">
          <ContactForm></ContactForm>
        </div>
      </div>
    </div>
  );
}
