import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import AnchorLink from 'react-anchor-link-smooth-scroll';


import Offerings from '../components/sections/Offerings';
import AboutUs from '../components/sections/AboutUs';
import Partners from '../components/sections/Partners';
import Customers from '../components/sections/Customers';
import ContactUs from '../components/sections/ContactUs';

const Index = () => (
  <Layout>
    <section id="hero" className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Welcome to Sigar Indonesia
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Your one stop solution for business and digital transformation.
          </p>
          <p className="mt-8 md:mt-12">
            <AnchorLink
              className="py-4 px-12 bg-primary hover:bg-sigarPurple hover:text-white rounded text-white"
              href='#about-us'
            >
              Learn More
            </AnchorLink>
          </p>
          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
        <div className="p-5 lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="about-us" className="lg:py-20">
      <AboutUs></AboutUs>
    </section>
    <section id="offerings" className="lg:py-20">
      <Offerings></Offerings>
    </section>
    <section id="partners" className="lg:py-20">
      <Partners></Partners>
    </section>
    <section id="customers" className="lg:py-20">
      <Customers></Customers>
    </section>
    <section id="contact-us" className="lg:py-20">
      <ContactUs></ContactUs>
    </section>
  </Layout>
);

export default Index;
