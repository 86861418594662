import React from 'react';

import HPLogo from '../../images/hp-logo.png';
import InspiraLogo from '../../images/inspira-logo.jpeg';
import PlutekLogo from '../../images/plutek-logo.jpeg';
import UniphoreLogo from '../../images/uniphore-logo.png';
import CodebuggedLogo from '../../images/codebugged-logo.jpeg';
import MSTLogo from '../../images/mst-logo.png';
import OutlierLogo from '../../images/outlier-logo.png';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const partners = [
  { name: 'HP', logo: HPLogo }, 
  { name: 'Uniphore', logo: UniphoreLogo }, 
  { name: 'Multi System Technologies', logo: MSTLogo }, 
  { name: 'Outlier Solutions', logo: OutlierLogo }, 
  { name: 'Codebugged', logo: CodebuggedLogo},
  { name: 'Inspira', logo: InspiraLogo }, 
  { name: 'Plutek', logo: PlutekLogo }, 
];

export const Partners = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Our Partners</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
        </h2>
        <p className="text-gray-700 lg:text-md lg:max-w-md">
          Our strategic partnerships give us the edge to provide best-in-class services to our customers
        </p>
      </div>

      <Carousel
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        transitionDuration={500}
        className="z-10"
      >
        {partners.map((partner) => (
          <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <img className="object-contain w-full h-56 md:h-64 xl:h-80" src={partner.logo} alt={partner.name} />
          </div>
        ))}
      </Carousel>
      {/* <div className="text-center">
        <a
          href="/"
          aria-label=""
          className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
        >
          See more
          <svg className="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
            <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
          </svg>
        </a>
      </div> */}
    </div>
  );
};

export default Partners;
